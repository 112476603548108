<template>
  <aheader/>
  <section class="section">
    <div class="title mt-4 is-spaced">
      来店予約について
    </div>
    <div class="subtitle">
      <p class="is-size-5">
        ふとんのオーダーメイドや定期点検のご予約は、お電話より受け付けております。
      </p>
      <p>
        営業時間(火曜を除く、10:00～16:00)の間、いつでもお待ちしております。お気軽にお問い合わせください。
      </p>
      <p class="is-size-5 mt-3">
        TEL <a href="tel:066-954-1515">06-6954-1515</a>
      </p>
    </div>
  </section>
  <afooter/>
</template>
<script>
export default {
  data() {
    return {
      name:"",
      mail:"",
      statusOrder:0,
      statusCheck:0,
    }
  },
  mounted() {
    this.scrollTop()
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
